<template>
  <div>
    <div class="page-fund-header">
      <h3>Fundraising API</h3>
      <p>
        You can check the Fundraising API doc
        <a target="_blank" href="https://doc.datagma.com">here</a><br />
        It's completely free. Enjoy! <br />
        This table is updated daily. Are you curious to see how it works?
        <a target="_blank" href="https://datagma.com/recently-funded-companies/"
          >Click here</a
        >
      </p>
    </div>
    <div class="table-hadding">
      <h3>Company Table</h3>
    </div>
    <iframe
      class="airtable-embed"
      src="https://airtable.com/embed/shr5l5qL4y0HfiM26?backgroundColor=cyan&viewControls=on"
      frameborder="0"
      onmousewheel=""
      width="100%"
      height="533"
      style="background: transparent; border: 1px solid #ccc;"
    ></iframe>
    <!-- <iframe
      class="airtable-embed"
      src="https://airtable.com/embed/shrdNjByhfIFr70GO?backgroundColor=cyan&viewControls=on"
      frameborder="0"
      onmousewheel=""
      width="100%"
      height="533"
      style="background: transparent; border: 1px solid #ccc;"
    ></iframe> -->
    <div class="table-hadding">
      <h3>Employee Table</h3>
    </div>
    <iframe
      class="airtable-embed"
      src="https://airtable.com/embed/shrtY12wE8LRPcRg2?backgroundColor=cyan&viewControls=on"
      frameborder="0"
      onmousewheel=""
      width="100%"
      height="533"
      style="background: transparent; border: 1px solid #ccc;"
    ></iframe>
  </div>
</template>
<script>
import { loadingService } from "../../common/services/loading.service";
export default {
  name: "fundraising-page",
  data() {
    return {};
  },
  async beforeMount() {
    this.$eventBus.$emit("callCreditMethod");
  },
  mounted() {
    loadingService.setloader(true);
    setTimeout(() => {
      loadingService.setloader(false);
    }, 2000);
  },
  methods: {},
};
</script>
<style scoped>
.page-fund-header {
  padding: 40px 60px;
  margin: 0 0 40px;
  background: rgb(247 248 250);
}
.table-hadding {
  padding: 4px 60px;
}
</style>
